/**
 * @file src/components/PlayVideo.js
 * The page that plays the video and displays info about the video
 */

/*
passing id from the parent cmp to this cmp, use this example:
const video = {
  title: 'sometitle',
  id: '1',
};
<Link to={{pathname: "/play_video", videoId: video.id }}> Show Video </Link>

in this cmp you can acsess the param from props with:
props.location.videoId
*/

// standard imports
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// need to connect to backend
import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';

// import css
import './PlayVideo.css';

import Video from './Video';

const PlayVideo = (props) => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently } = useAuth0();

  // state variable to store videos
  const [video, setVideo] = useState({});
  const [videoID, setVideoID] = useState(-1);
  const [modal, setModal] = useState(false);
  const { user } = useAuth0();

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getVideoById();
    // eslint-disable-next-line
  }, []);

  /**
   * Make api request to retrive a video by Id from the database
   * then sets that video to a state variable
   * @param {string} id the id of the video we want to get
   */
  const getVideoById = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      // we cannot use videoID because we can't rely on that useState
      // function completing before this axios request gets sent
      const response = await axios.get(`/api/videos/${props.match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // what do we want to show to the user
      setVideoID(props.match.params.id);
      setVideo(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoById caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * Toggles the modal's display
   * if the modal is closing refetch video list
   */
  const toggleModal = () => {
    setModal(!modal);
  };

  const favVideo = async (videoId) => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      await axios.post('/api/favorite', {
      videoId: videoId,
      user: user.email,
      },
      {headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('favVideo caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application

    <div>
      {videoID === 0 ? (
        <h1>Loading...</h1>
      ) : (
        <Video id={props.match.params.id} />
      )}
      <div className="alignRight">
        <Button color="warning" onClick={() => favVideo(props.match.params.id)}>Favorite</Button>
        <Button onClick={() => toggleModal()}>Show Video Details</Button>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Video Details</ModalHeader>
        <ModalBody>
          <div>
            <span className="video-detail">Title: </span>
            <span className="video-data">{video.title}</span>
          </div>
          <div>
            <span className="video-detail">Creator: </span>
            <span className="video-data">{video.creator}</span>
          </div>
          <div>
            <span className="video-detail">Contributors: </span>
            <span className="video-data">{video.contributors}</span>
          </div>
          <div>
            <span className="video-detail">Publisher: </span>
            <span className="video-data">{video.publisher}</span>
          </div>
          <div>
            <span className="video-detail">Description: </span>
            <span className="video-data">{video.description}</span>
          </div>
          <div>
            <span className="video-detail">Subject: </span>
            <span className="video-data">{video.subject}</span>
          </div>
          <div>
            <span className="video-detail">Video Date Created: </span>
            <span className="video-data">{video.videoDateCreated}</span>
          </div>
          <div>
            <span className="video-detail">Video Date: </span>
            <span className="video-data">{video.videoDate}</span>
          </div>
          <div>
            <span className="video-detail">Type: </span>
            <span className="video-data">{video.type}</span>
          </div>
          <div>
            <span className="video-detail">Format: </span>
            <span className="video-data">{video.format}</span>
          </div>
          <div>
            <span className="video-detail">Extent: </span>
            <span className="video-data">{video.extent}</span>
          </div>
          <div>
            <span className="video-detail">Rights: </span>
            <span className="video-data">{video.rights}</span>
          </div>
          <div>
            <span className="video-detail">Identifier: </span>
            <span className="video-data">{video.identifier}</span>
          </div>
          <div>
            <span className="video-detail">Source: </span>
            <span className="video-data">{video.source}</span>
          </div>
          <div>
            <span className="video-detail">Language: </span>
            <span className="video-data">{video.language}</span>
          </div>
          <div>
            <span className="video-detail">Audience: </span>
            <span className="video-data">{video.audience}</span>
          </div>
          <div>
            <span className="video-detail">Provenance: </span>
            <span className="video-data">{video.provenance}</span>
          </div>
          <div>
            <span className="video-detail">Relation: </span>
            <span className="video-data">{video.relation}</span>
          </div>
          <div>
            <span className="video-detail">Coverage: </span>
            <span className="video-data">{video.coverage}</span>
          </div>
          <div>
            <span className="video-detail">Filename: </span>
            <span className="video-data">{video.filename}</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="modal-cancel" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PlayVideo;
