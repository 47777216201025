/**
 * @file
 * @description This component will allow users to view information about who created the app
 */
// standard import
import React from 'react';

// necessary imports for this page
import './About.css';
import logo from '../assets/AU_library_logo.png';

/**
 * @component
 * @description A component which renders information about the project and its authors
 */
const About = () => {
  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // displayed upon clicking the "About" button in the hamburger menu
    <div className="About">
      <div>
        <img
          className="horizontal-logo"
          src={logo}
          alt="Anderson University Library Logo"
        ></img>
      </div>
      <div className="About">
        <p>
          This web app was developed by Dr. Coy's Capstone I class throughout
          the Fall of 2020.
        </p>
        <p>
          Special thanks to Dr. Coy and Brian Schultz for their contributions in
          creating the base application.
        </p>
        <div>
          <p>The awesome students responsible for creating this app were: </p>
          <ul>
            Andrew Nead, Ben Mumma, Jonah Vallance, Matthew Millford, Quay'Lee
            Rolle, Wesley Erny, and William Clemens
          </ul>
        </div>
        <p className="mailtag">
          To get in contact with an Admin with questions or concerns,
          <a href="mailto:cpsc@anderson.edu"> please click here</a>
        </p>
      </div>
    </div>
  );
};

export default About;
