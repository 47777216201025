/**
 * @file A component to allow users to send an email reporting a problem
 * with the application.
 */

// standard imports
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '../react-auth0-wrapper';

// for this page
import './ReportIssues.css';
import { Form, Label, Input, Button, FormGroup, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import logo from '../assets/AU_library_logo.png';
import { Redirect } from 'react-router-dom';

/**
 * @component ReportIssues
 * @description Render a page to allow emails to be sent through the app
 */
const ReportIssues = () => {
  // we will need to have the token to connect to the
  // email endpoint on the backend
  const { getTokenSilently, user } = useAuth0();
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [redirect,setRedirect] = useState(false);


  /**
   * stores the contents of the form in a local variable,
   * so we can send their subject/body message from the form
   */
  const form = {
    subject: null,
    body: null,
  };

  /**
   * when the user submits the form, an email is sent with subject/body
   * from the form
   */
  const handleSubmit = async () => {
    setDisabled(true);
    const token = await getTokenSilently();

    // construct the to line, so that the user also gets the email
    const toList = 'jjcoy@anderson.edu, ' + user.email;

    // connect to the endpoint and send an email
    const response = await axios.post(
      '/api/send',
      {
        name: 'Libstream App',
        email: `${toList}`,
        subject: `${form.subject}`,
        messageHTML: `${form.body}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // after we send the email, let the user know if it succeeded
    let statusCheck = response.data.gmail;
    if (statusCheck.substr(0, statusCheck.indexOf(' ')) === 'Success') {
      toggleModal();    
    } else {
      alert('Oops, something went wrong. Please try again.');
    }
  };
  
  const toggleModal = () => {
    setModal(!modal);
  };

  const closeAndRedirect = () => {
    toggleModal();
    setRedirect(true);
  }

  return (
    <div className="Report">
      {redirect ? <Redirect to="/" /> : null}
      <img className="logo" src={logo} alt="Anderson University Logo"></img>
      <b className="intro">If you have found an issue in our site, please report it below</b>

      {/* Get the information needed from the user */}
      <Form>
        <Container>
          <FormGroup row>
            {/* Along with user's name, email, and other information provided */}
            <Label sm={3} className="labels">From: </Label>
            <p className="user-details">
              {user.name} ({user.email})
            </p>
          </FormGroup>
          <FormGroup row>
            <Label for="title" sm={3} className="labels">
              Subject:{' '}
            </Label>
            <Input
              name="subject_input"
              type="text"
              id="title"
              onChange={(input) => (form.subject = input.target.value)}
            ></Input>
          </FormGroup>

          <FormGroup row>
            <Label for="description" sm={3} className="labels">
              Description:{' '}
            </Label>
            <Input
              name="description_input"
              type="textarea"
              id="description"
              onChange={(input) => (form.body = input.target.value)}
            />
          </FormGroup>
          <FormGroup row>
            <Button onClick={handleSubmit} disabled={disabled} className="Submit">
              Submit
            </Button>
          </FormGroup>
        </Container>
      </Form>
      <Modal isOpen={modal} toggle={toggleModal} >
        <ModalHeader toggle={toggleModal}>Email Sent</ModalHeader>
        <ModalBody>
          <h2>Email was sent successfully!</h2>
        </ModalBody>
        <ModalFooter>
          <Button className="modal-close" onClick={closeAndRedirect}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReportIssues;
