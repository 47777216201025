/**
 * Browse.js
 * This component will allow for the browsing of videos
 */

import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  CardTitle,
  CardDeck,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// need to connect to backend
import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';

//CSS for this page
import './Browse.css';

const Browse = (props) => {
  //useState variable for dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // state variable to store list of videos
  const { getTokenSilently } = useAuth0();
  const [videoList, setVideoList] = useState([]);
  let [searchParam, setSearchParam] = useState('Title');

  const { user } = useAuth0();

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getVideoList();
    // the next line eliminates the error:
    // React Hook useEffect has a missing dependency: 'getUsersSubmissionTitles'.
    // Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * a function to connect to the backend
   * sets the state variable videoList
   */
  const getVideoList = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      const response = await axios.get('/api/videos', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to show to the user
      setVideoList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoList caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * a function to connect to the backend
   * sets the state variable videoList base on users search criteria
   */
  const getSearchList = async (searchCriteria) => {
    try {
      // get the token
      const token = await getTokenSilently();

      searchParam = searchParam.toLowerCase();
      // connect to the endpoint 'api/videos', send authentication token
      const response = await axios.post('/api/videos/search', {
        field: searchParam,
        searchCriteria: searchCriteria,
      },
        {headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );

      // what do we want to show to the user
      setVideoList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoList caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  const favVideo = async (videoId) => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      await axios.post('/api/favorite', {
      videoId: videoId,
      user: user.email,
      },
      {headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('favVideo caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  //Method to toggle dropdown
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // const handleClick = () => setVideoId(videoList.eachVideo.id);

  // JJC:  Commented out, was preventing deploy to Netlify under "unused vars prohibited"
  // const getSelectedVideo = async (param) => {
  //   try {
  //     const token = getTokenSilently();
  //     const response = await axios.get(`/api/videos/${param}/file`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log(response.data);
  //     setVideoFilePath(response.data);
  //   } catch (error) {
  //     console.error('getSelectedVideo caught error:' + error);
  //   }
  // };

  return (
    <div>
      {/*Dropdown component for the page*/}
      <Dropdown
        className="dropdown-menu-category"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret>Search By</DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            id="Title"
            onClick={() =>
              setSearchParam(document.getElementById('Title').innerHTML)
            }
          >
            Title
          </DropdownItem>
          <DropdownItem
            id="Creator"
            onClick={() =>
              setSearchParam(document.getElementById('Creator').innerHTML)
            }
          >
            Creator
          </DropdownItem>
          <DropdownItem
            id="Contributors"
            onClick={() =>
              setSearchParam(document.getElementById('Contributors').innerHTML)
            }
          >
            Contributors
          </DropdownItem>
          <DropdownItem
            id="Subject"
            onClick={() =>
              setSearchParam(document.getElementById('Subject').innerHTML)
            }
          >
            Subject
          </DropdownItem>
          <DropdownItem
            id="Date"
            onClick={() =>
              setSearchParam(document.getElementById('Date').innerHTML)
            }
          >
            Date
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {/*Simple filter text input box*/}
      <InputGroup className="filter-videos">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{searchParam}</InputGroupText>
        </InputGroupAddon>
        <Input placeholder="Search Criteria" id="searchInput" />
        <Button
          onClick={() =>
            getSearchList(document.getElementById('searchInput').value)
          }
        >
          Search
        </Button>
        <Button color="danger" onClick={() => getVideoList()}>
          Clear Search
        </Button>
      </InputGroup>

      <CardDeck>
        {videoList.map((eachVideo) => {
          return (
            <Card key={eachVideo.id}>
              <CardBody>
                <CardTitle className="cardTitle" tag="h5">
                  {eachVideo.title}
                  &nbsp;&nbsp;
                  <Button color="warning" onClick={() => favVideo(eachVideo.id)}>Favorite</Button>
                </CardTitle>
              </CardBody>
              <Link
                to={{
                  pathname: `/play_video/${eachVideo.id}`,
                  state: { id: eachVideo.id },
                }}
                width="100%"
                className="videoLink"
              >
                <video
                  preload="auto"
                  controls="controls"
                  className="videoPlayer"
                >
                  <source
                    src={`http://localhost:5000/api/videos/${eachVideo.id}/file`}
                    type="video/mp4"
                  />
                </video>
              </Link>
            </Card>
          );
        })}
      </CardDeck>
    </div>
  );
};

export default Browse;
