/**
 * @file
 * @description The main component used for containing and routing to child components.
 */

// standard react components
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAuth0 } from './react-auth0-wrapper';

// This file provides the PrivateRouter functionality in the Switch below
import PrivateRoute from './PrivateRoute';

// css file and other assets needed
import './App.css';

// bring in other components
import Profile from './components/Profile';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import ViewFavorites from './components/ViewFavorites';
import ReportIssues from './components/ReportIssues';
import ManageUsers from './components/ManageUsers';
import Browse from './components/Browse';
import Video from './components/Video';
import ManageVideos from './components/ManageVideos';
import PlayVideo from './components/PlayVideo';
import PleaseLogin from './components/PleaseLogin';

/**
 * @component
 * @description This is the App component, referenced from index.js, which
 * drives the React Single-Page Application (SPA)
 */
const App = () => {
  // get information from Auth0, pulling in specifically the
  const { loading } = useAuth0();
  if (loading) {
    return <div>Loading...</div>;
  }

  /* ready to create the HTML for this section (referenced from index.js)
   * Note that JSX comments, inside the return(), must show up
   * inside {} using star-slash style comments */
  return (
    <div className="App">
      {/* The BrowserRouter package gives our Single Page Application the
       * ability to use routes in the URL.
       */}
      <BrowserRouter>
        {/* The NavBar component will be shown in a header, for all pages */}
        <header>
          <NavBar />
        </header>

        {/* Switch will connect the route in the URL to the component
         * This is sort of like the body of the page.  The code below
         * will choose one of the following items to display, depending on the
         * path in the URL.
         */}
        <Switch>
          {/* "Routes" can be accessed whether or not user is logged in */}
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          {/* PrivateRoutes can only be accessed when logged in,
           * this functionality is provided in the file src/PrivateRoute.js */}
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/view_favorites" component={ViewFavorites} />
          <PrivateRoute path="/report_issues" component={ReportIssues} />
          <PrivateRoute path="/browse_videos" component={Browse} />
          <PrivateRoute path="/manage_users" component={ManageUsers} />
          <PrivateRoute path="/sample_video" component={Video} />
          <PrivateRoute path="/manage_videos" component={ManageVideos} />
          <PrivateRoute
            path="/play_video/:id"
            render={(props) => <PlayVideo {...props} />}
          />
          {/* Fallback route for all unknown URLs */}
          <Route component={PleaseLogin} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
