/**
 * src/components/Home.js,
 * The main page of the app.
 */

// standard imports
import React, { useEffect } from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';

// for this page
import './Home.css';
import logo from '../assets/AU_library_logo.png';

const Home = () => {
  /* Auth0 stuff, to customize the view, depending on if the user is
   * logged in (isAuthenticated = true) or not (=false) */
  const { isAuthenticated } = useAuth0();
  const { getTokenSilently } = useAuth0();
  const { user } = useAuth0();

  // execute one time, on first component render
  useEffect(() => {
    if (isAuthenticated) {
      registerUser();
    }
    // the next line eliminates the error:
    // React Hook useEffect has a missing dependency: 'getUsersSubmissionTitles'.
    // Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * a function to connect to the backend
   * checks to see if the user is in the database
   */
  const registerUser = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      await axios.post(
        '/api/registerUser',
        {
          user: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoList caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application
    <div className="Home">
      <div className="logged-in">
        <div>
          <div>
            <img
              className="vertical-logo"
              src={logo}
              alt="Anderson University Logo"
            ></img>
          </div>
          <div className="row">
            <div className="col-header">
              LibStream: the Library Streaming Application
            </div>
          </div>
          {/* if we are not logged in, display this text */}
          {isAuthenticated === false && (
            <div className="row">
              <span className="notLoggedIn">Please login to continue.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
