/**
 * @file
 * @description Provides a way for a user to view their profile
 */

// standard react components
import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';

// css for the Profile component
import './Profile.css';

const Profile = () => {
  // bring in the Auth0 stuff we need
  const { loading, user } = useAuth0();

  // if Auth0 tells us it is loading, or if we don't get a user object
  if (loading || !user) {
    return <div>Loading...</div>;
  }

  // ready to render the information from the user object
  return (
    <div className="Profile">
      {/* Display the profile picture from user's email */}
      <img src={user.picture} alt="Profile" />

      {/* Along with user's name, email, and other information provided */}
      <h2 className="username">{user.name}</h2>
      <p>{user.email}</p>
      <p>Roles: {user['http://cel.anderson.edu/libstream/roles']}</p>
      <p> The JSON user information contains: </p>
      <code>{JSON.stringify(user, null, 2)}</code>
    </div>
  );
};

export default Profile;
