import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardDeck,
  Button
} from 'reactstrap';
import { Link } from 'react-router-dom';

// need to connect to backend
import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios'

const ViewFavorites = () => {

  const { getTokenSilently } = useAuth0();
  const [videoList, setVideoList] = useState([]);
  const { user } = useAuth0();

  useEffect(() => {
    // run the query for the database
    getFavList();
    // the next line eliminates the error:
    // React Hook useEffect has a missing dependency: 'getUsersSubmissionTitles'.
    // Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * a function to connect to the backend
   * sets the state variable videoList
   */
  const getFavList = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      const response = await axios.post('/api/videos/favs', {
        user: user.email,
      },
      {headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    );

     // what do we want to show to the user
     setVideoList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoList caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  const unfavVideo = async (videoId) => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      await axios.post('/api/unfavorite', {
        videoId: videoId,
        user: user.email,
      },
      {headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    );

    } catch (error) {
      // if we had a problem, it is caught here
      console.error('unfavVideo caught error:' + error);
      // TODO:  inform user of the error
    }
  };


    return (
    // Display the favorite videos that the user has saved to their accounts
      <div>
      <CardDeck>
        {videoList.map((eachVideo) => {
          return (
            <Card key={eachVideo.id}>
              <CardBody>
                <CardTitle className="cardTitle" tag="h5">
                  {eachVideo.title}
                  &nbsp;&nbsp;
                  <Button color="warning" onClick={() => unfavVideo(eachVideo.id)}>Unfavorite</Button>
                </CardTitle>
              </CardBody>
              <Link
                to={{
                  pathname: `/play_video/${eachVideo.id}`,
                  state: { id: eachVideo.id },
                }}
                width="100%"
                className="videoLink"
              >
                <video preload="auto" controls="controls" className="videoPlayer">
                  <source
                    src={`http://localhost:5000/api/videos/${eachVideo.id}/file`}
                    type="video/mp4"
                  />
                </video>
              </Link>
            </Card> 
          );
        })}
      </CardDeck>
      </div>
  );
};

export default ViewFavorites;
