/**
 * @file
 * @description Provide a way for the Manager to edit/add/delete/etc videos
 */

// React imports
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import { DataGrid } from '@material-ui/data-grid';

// need to connect to backend
import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';

// get css
import './ManageVideos.css';

/**
 * @component
 * @description The ManageVideos component provides a way for the Manager
 * to edit/add/delete/etc videos
 */
const ManageVideos = () => {
  // will need to get the access token to send to the backend
  // this token has info about this user (including email)
  const { getTokenSilently } = useAuth0();

  // state variable to store list of videos
  const [videoList, setVideoList] = useState([]);

  // which type of modal to display
  const [modalType, setmodalType] = useState('');

  // whether or not to display the modal
  const [modal, setModal] = useState(false);

  // which video is currently selected (object with all data fields in db)
  const [currentlySelectedVideo, setCurrentlySelectedVideo] = useState({});

  //Set video file to be uploaded
  const [selectedFile, setSelectedFile] = useState(null);

  //fields that must have values for video to be created
  const [title, setTitle] = useState('');
  const [creator, setCreator] = useState('');
  const [videoDate, setVideoDate] = useState('');
  const [rights, setRights] = useState('');

  //set boolean for input validation
  const [disable, setDisable] = useState(true);

  //used for hiding/showing the spinner
  const [spinnerHidden, setSpinnerHidden] = useState(true);

  //state for loading video list
  const [isLoaded, setIsLoaded] = useState(false);
  /**
   * local copy of the table's currently selected rows
   * not a useState variable because the DOM rerendering
   * unselects rows
   */
  let currentlySelectedRows = [];

  /**
   * list of objects that define what columns to display in the table
   */
  const columns = [
    { field: 'title', headerName: 'Title', width: 300 },
    { field: 'creator', headerName: 'Creator', width: 300 },
    { field: 'videoDate', headerName: 'Video Date', width: 150 },
    { field: 'filename', headerName: 'Filename', width: 300 },
  ];

  // execute one time, on first component render

  useEffect(() => {
    //only run this once
    if (!isLoaded) {
      //or isLoaded === false if you prefer
      // run the query for the database
      getVideoList();
      // set isLoaded to true so this doesn't fire off on every onChange input event :)
      setIsLoaded(true);
    }
    if (inputValidate()) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    // eslint-disable-next-line
  }, [title, creator, videoDate, rights]);

  /**
   * a function to connect to the backend which sets the state variable
   * videoList
   */
  const getVideoList = async () => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      const response = await axios.get('/api/videos', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // what do we want to show to the user
      setVideoList(response.data.results);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getVideoList caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * a function to keep a local object of currently selected row
   * @param {object} selected row object passed from the select event
   */
  const updateSelectedRows = (selectedRows) => {
    // empty the list out
    currentlySelectedRows = [];

    // build a list object, [ {row 1 obj}, {row 2 obj}]
    if (selectedRows.rowIds.length > 0) {
      selectedRows.rowIds.forEach((row) => {
        currentlySelectedRows.push(videoList[row - 1]); // start counting at zero, so -1
      });
    }
  };

  // /**
  //  * demo doing stuff with multiple rows selected (not fully implimented)
  //  * probs will be a mass edit of some form
  //  */
  // const doSomthingWithSelection = () => {
  //   let titles = [];
  //   currentlySelectedRows.forEach((row) => {
  //     titles.push(row.title);
  //   });
  //   alert(titles);
  // };

  /**
   * check if there is only one row selected;
   * if so update the currentlySelectedVideo state var,
   * then show the edit modal
   */
  const handleEditClick = () => {
    setmodalType('Update');

    if (currentlySelectedRows.length === 1) {
      // select this row
      setCurrentlySelectedVideo(currentlySelectedRows[0]);

      // set fields for input validation
      setTitle(currentlySelectedRows[0].title);
      setCreator(currentlySelectedRows[0].creator);
      setVideoDate(currentlySelectedRows[0].videoDate);
      setRights(currentlySelectedRows[0].rights);

      // display modal, with "Update", and prefilled info
      toggleModal();
    } else {
      alert('Please select just one row to edit.');
    }
  };

  /**
   * show empty modal to create a video
   */
  const handleCreateClick = () => {
    setmodalType('Create');
    setCurrentlySelectedVideo({});
    toggleModal();
  };

  /**
   * Deletes a video from the data table, database, and s3 bucket
   */
  const handleDeleteClick = async () => {
    if (currentlySelectedRows.length === 1) {
      await deleteVideoFile(currentlySelectedRows[0].id);
      await deleteVideoDatabase(currentlySelectedRows[0].id);
    } else {
      alert('ERROR: No rows selected');
    }
  };

  /**
   * toggles the modal's display
   * if the modal is closing refetch video list
   */
  const toggleModal = () => {
    if (modal) {
      // if it was already up, update the video list
      getVideoList();
      // also, reset the fields for input validation
      setTitle('');
      setCreator('');
      setVideoDate('');
      setRights('');
    }
    setModal(!modal);
  };

  /**
   * handles when a video is to be created or updated
   * wrapper function
   */
  const handleModalBtnClick = () => {
    const video = buildVideoObj();
    if (modalType === 'Create') {
      createVideo(video);
    } else {
      updateVideo(video);
    }
  };

  const inputValidate = () => {
    if (title !== '' && creator !== '' && videoDate !== '' && rights !== '') {
      return true;
    } else {
      return false;
    }
  };

  /**
   * builds a video from the input boxes
   * with the values in the edit modal's text boxes
   * @return {object} the video to be updated/created
   */
  const buildVideoObj = () => {
    let video = {}; // copy current video meta data
    let inputs = document.querySelectorAll('.input'); // get all input boxes

    // iterate through each input and update video's meta data
    inputs.forEach((input) => {
      video[input.id] = input.value;
    });

    let filePath = video.filename.split('\\').pop();
    video.filename = '/videos/' + filePath;
    video = Object.assign(currentlySelectedVideo, video);
    return video;
  };

  /**
   * callout that updates a video's meta data
   * in the data base
   * @param {object} video object to be updated
   */
  const updateVideo = async (video) => {
    try {
      // get the token
      const token = await getTokenSilently();
      // connect to the endpoint 'api/videos', send authentication token
      const responce = await axios.put(
        '/api/videos',
        {
          video: JSON.stringify(video),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // throw an error if the database didnt update
      if (responce.data.results[0] !== 1) {
        throw new Error(
          "Cannot update Video! Maybe that Video doesn't exist with that Id..."
        );
      } else {
        toggleModal();
      }
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('updateVideo caught error:' + error);
      // TODO:  inform user of the error
    }
  };

  /**
   * creates a video in the data base
   * @param object video object to be create w/ and id field
   */
  const createVideo = async (video) => {
    try {
      // get the token
      const token = await getTokenSilently();

      // connect to the endpoint 'api/videos', send authentication token
      const response = await axios.post(
        '/api/videos',
        {
          video: JSON.stringify(video),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // disable buttons and turn on spinner
      setDisable(true);
      setSpinnerHidden(false);
      await uploadVideo(selectedFile);

      // throw an error if the database didnt create the video
      if (typeof response.data.results.id === 'undefined') {
        throw new Error('Cannot create Video...');
      } else {
        toggleModal();
      }
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('createVideo caught error:' + error);
      // TODO:  inform user of the error
    }

    // re-enable buttons and turn off spinner
    setDisable(false);
    setSpinnerHidden(true);
  };
  /**
   *
   * @param {object} file video file (in mp4 format) to be uploaded to S3
   */
  const uploadVideo = async () => {
    try {
      const token = await getTokenSilently();

      // build data to send to the post request
      const formData = new FormData();
      formData.append('video', selectedFile, selectedFile.name);

      // send the request
      const response = await axios.post('/api/videos/file/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('---->uploadVideo response is: ', response.data);
    } catch (error) {
      console.error('uploadVideo caught error:' + error);
    }
  };
  //set file hook value to file input by the manager
  const OnFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * deletes a video file, removing it from AWS
   * @param id number from database of the video to delete, which allows us to retrieve
   * file name used on AWS
   */
  const deleteVideoFile = async (videoId) => {
    // get the token
    const token = await getTokenSilently();
    // send request to backend to delete the file
    try {
      const response = await axios.delete(
        `/api/videos/${videoId}/file/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            videoId: videoId,
          },
        }
      );
      console.log(response);
      // TODO: this is not accurately reflecting error conditions
      // if (typeof response.data.results === 'undefined') {
      //   throw new Error('Cannot delete video....');
      // } else {
      //   alert('Video deleted successfully!');
      // }
    } catch (error) {
      console.error('deleteVideo caught error:' + error);
    }
  };

  const deleteVideoDatabase = async (videoId) => {
    const token = await getTokenSilently();
    try {
      const response = await axios.delete(`/api/videos/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          videoId: videoId,
        },
      });
      console.log(response);
    } catch (error) {
      console.error('deleteVideoDatabase caught error: ' + error);
    }
  };

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    <div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={videoList}
          columns={columns}
          pageSize={10}
          checkboxSelection
          onSelectionChange={(selectedRows) => {
            updateSelectedRows(selectedRows);
          }}
        />
      </div>
      <div>
        <Button className="table-btn" onClick={handleEditClick}>
          Edit Video
        </Button>
        <Button className="table-btn" onClick={handleCreateClick}>
          Create Video
        </Button>
        <Button className="table-btn" onClick={handleDeleteClick}>
          Delete Video
        </Button>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modalType} Video</ModalHeader>
        <ModalBody>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Title*</Label>
              <Input
                className="input"
                type="input"
                id="title"
                placeholder="Enter a title..."
                defaultValue={currentlySelectedVideo.title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Creator*</Label>
              <Input
                className="input"
                type="input"
                id="creator"
                placeholder="Enter a creator..."
                defaultValue={currentlySelectedVideo.creator}
                onChange={(e) => setCreator(e.target.value)}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Contributors</Label>
              <Input
                className="input"
                type="input"
                id="contributors"
                placeholder="Enter a contributors..."
                defaultValue={currentlySelectedVideo.contributors}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Publisher</Label>
              <Input
                className="input"
                type="input"
                id="publisher"
                placeholder="Enter a publisher..."
                defaultValue={currentlySelectedVideo.publisher}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Subject</Label>
              <Input
                className="input"
                type="input"
                id="subject"
                placeholder="Enter a subject..."
                defaultValue={currentlySelectedVideo.subject}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Video Date Created</Label>
              <Input
                className="input"
                type="input"
                id="videoDateCreated"
                placeholder="Enter a video date created..."
                defaultValue={currentlySelectedVideo.videoDateCreated}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Video Date*</Label>
              <Input
                className="input"
                type="input"
                id="videoDate"
                placeholder="Enter a video date..."
                defaultValue={currentlySelectedVideo.videoDate}
                onChange={(e) => setVideoDate(e.target.value)}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Type</Label>
              <Input
                className="input"
                type="input"
                id="type"
                placeholder="Enter a type..."
                defaultValue={currentlySelectedVideo.type}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Format</Label>
              <Input
                className="input"
                type="input"
                id="format"
                placeholder="Enter a format..."
                defaultValue={currentlySelectedVideo.format}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Extent</Label>
              <Input
                className="input"
                type="input"
                id="extent"
                placeholder="Enter a extent..."
                defaultValue={currentlySelectedVideo.extent}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Rights*</Label>
              <Input
                className="input"
                type="input"
                id="rights"
                placeholder="Enter a rights..."
                defaultValue={currentlySelectedVideo.rights}
                onChange={(e) => setRights(e.target.value)}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Identifier</Label>
              <Input
                className="input"
                type="input"
                id="identifier"
                placeholder="Enter a identifier..."
                defaultValue={currentlySelectedVideo.identifier}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Source</Label>
              <Input
                className="input"
                type="input"
                id="source"
                placeholder="Enter a source..."
                defaultValue={currentlySelectedVideo.source}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Language</Label>
              <Input
                className="input"
                type="input"
                id="language"
                placeholder="Enter a language..."
                defaultValue={currentlySelectedVideo.language}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Audience</Label>
              <Input
                className="input"
                type="input"
                id="audience"
                placeholder="Enter a audience..."
                defaultValue={currentlySelectedVideo.audience}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Provenance</Label>
              <Input
                className="input"
                type="input"
                id="provenance"
                placeholder="Enter a provenance..."
                defaultValue={currentlySelectedVideo.provenance}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container">
              <Label className="input-lable">Relation</Label>
              <Input
                className="input"
                type="input"
                id="relation"
                placeholder="Enter a relation..."
                defaultValue={currentlySelectedVideo.relation}
              />
            </div>
            <div className="input-container">
              <Label className="input-lable">Coverage</Label>
              <Input
                className="input"
                type="input"
                id="coverage"
                placeholder="Enter a coverage..."
                defaultValue={currentlySelectedVideo.coverage}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container single-input">
              <Label className="input-lable">Description</Label>
              <Input
                className="input"
                type="textarea"
                id="description"
                placeholder="Enter a description..."
                defaultValue={currentlySelectedVideo.description}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-container single-input">
              <Label className="input-lable">Video File</Label>
              <Input
                className="input"
                type="file"
                id="filename"
                onChange={OnFileChange}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Spinner className="spinner" color="danger" hidden={spinnerHidden} />
          <Button
            color="primary"
            disabled={disable}
            onClick={handleModalBtnClick}
          >
            {modalType}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ManageVideos;
