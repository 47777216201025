/**
 * @file
 * @description Provide a way for the Manager to manage users
 */

// standard imports
import React, { useState, useEffect } from 'react';

// for this page
import './ManageUsers.css';
//import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';
import config from '../management_auth_config.json';

const ManageUsers = () => {
  const [userList, setUserList] = useState([]);

  // execute one time, on first component render
  useEffect(() => {
    // run the query for the database
    getUserList();
    // the next line eliminates the error:
    // React Hook useEffect has a missing dependency: 'getUsersSubmissionTitles'.
    // Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * a function to connect to Auth0 to retrieve a list of users
   * sets the state variable UserList
   */
  const getUserList = async () => {
    try {
      // find the token here: https://manage.auth0.com/dashboard/us/au-libstream/apis/management/explorer

      const tokenResponse = await axios.post(
        `https://${config.domain}/oauth/token`,
        {
          client_id: `${config.clientId}`,
          client_secret: `${config.client_secret}`,
          audience: `${config.audience}`,
          grant_type: 'client_credentials',
        },
        {
          headers: { 'content-type': 'application/json' },
        }
      );

      const management_token = tokenResponse.data.access_token;

      // Auth0 documentation here: https://auth0.com/docs/users/user-search/retrieve-users-with-get-users-endpoint
      // connect to the endpoint 'https://au-libstream.us.auth0.com/api/v2/users', send authentication token
      const response = await axios.get(
        'https://au-libstream.us.auth0.com/api/v2/users',
        {
          headers: {
            Authorization: `Bearer ${management_token}`,
          },
        }
      );
      // make response into array of strings

      // what do we want to show to the user
      setUserList(response.data);
    } catch (error) {
      // if we had a problem, it is caught here
      console.error('getUserList caught error:' + error);
      // TODO:  inform user of the error
      // TODO:  for now, just fill the data so we can have something to work with:
      //setUserList(testdata);
    }
  };

  // this is the JSX (HTML) returned to be displayed on the page by App.js
  return (
    // Displayed upon first rendering of application
    <div className="ManageUsers">
      <h1>Manage Users</h1>
      <div className="UserList">
        <table>
          <thead>
            <tr>
              <th>FirstName</th>
              <th>LastName</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((eachUser) => {
              return (
                <tr>
                  <td>{eachUser.given_name}</td>
                  <td>{eachUser.family_name}</td>
                  <td>{eachUser.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageUsers;
