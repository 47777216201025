/**
 * @file src/components/NavBar.js
 * This component is displayed at the top of each page to ease navigation
 */

// standard react components
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAuth0 } from '../react-auth0-wrapper';
import { Navbar, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';

// css for the NavBar component
import './NavBar.css';

const NavBar = () => {
  // Auth0 stuff, to customize the view, depending on if the user is logged
  // in (isAuthenticated = true) or not (=false)
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  let isManager = false;
  if (isAuthenticated) {
    isManager = user['http://cel.anderson.edu/libstream/roles'].includes(
      'manager'
    );
  }

  // state var to track dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   * Toggles the hamburger menu dropdown
   */
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    // displayed on the top of the screen in every view/component
    <div>
      <Navbar className="NavBar">
        <Nav>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} className="menu-icon">
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
            >
              <div className="hamburger"></div>
              <div className="hamburger"></div>
              <div className="hamburger"></div>
            </DropdownToggle>
            <DropdownMenu>
              {/* always show Home link whether user logged in or not */}
              <div className="center-menu-link">
                <Link className="link" to="/" onClick={toggle}>
                  Home
                </Link>
              </div>
              <div className="center-menu-link">
                <Link
                  name="about"
                  className="link"
                  to="/about"
                  onClick={toggle}
                >
                  About
                </Link>
              </div>
              {/* only show these links if user is logged in */}
              {isAuthenticated && (
                <div className="center-menu-link">
                  <Link
                    name="view_favorites"
                    className="link"
                    to="/view_favorites"
                    onClick={toggle}
                  >
                    Favorites
                  </Link>
                </div>
              )}
              {isAuthenticated && (
                <div className="center-menu-link">
                  <Link
                    name="report_issues"
                    className="link"
                    to="/report_issues"
                    onClick={toggle}
                  >
                    Report Issue
                  </Link>
                </div>
              )}
              {isAuthenticated && (
                <div className="center-menu-link">
                  <Link
                    name="browse_videos"
                    className="link"
                    to="/browse_videos"
                    onClick={toggle}
                  >
                    Browse
                  </Link>
                </div>
              )}

              {/* Only show these links if user is logged in AND is a manager*/}
              {isAuthenticated && isManager && (
                <div className="center-menu-link">
                  <Link
                    name="manage_users"
                    className="link"
                    to="/manage_users"
                    onClick={toggle}
                  >
                    Manage Users
                  </Link>
                </div>
              )}
              {isAuthenticated && isManager && (
                <div className="center-menu-link">
                  <Link
                    name="manage_videos"
                    className="link"
                    to="/manage_videos"
                    onClick={toggle}
                  >
                    Manage Videos
                  </Link>
                </div>
              )}
            </DropdownMenu>
          </Dropdown>
        </Nav>
        <Nav>
          {/* always show Log In/Out button, but changes text on button whether user logged in or not */}
          {/* if not logged in, provide Log In button */}
          {!isAuthenticated && (
            <div>
              <button
                className="button-link"
                color="secondary"
                onClick={() => loginWithRedirect({})}
              >
                Log In
              </button>
            </div>
          )}
          {/* if logged in, provide Log Out button */}
          {isAuthenticated && (
            <div>
              <button
                className="button-link"
                color="secondary"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Log Out
              </button>
            </div>
          )}
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
