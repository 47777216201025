import React, { useEffect, useState } from 'react';

import { useAuth0 } from '../react-auth0-wrapper';
import axios from 'axios';

import './Video.css';

const Video = (props) => {
  //useState for video to be converted to URL
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getTokenSilently } = useAuth0();
  //const [token, setToken] = useState(null);
  const [videoID, setVideoID] = useState(props.id);

  useEffect(() => {
    const doStuff = async () => {
      // const myToken = await getTokenSilently();
      // setToken(myToken);
      setVideoID(props.id);
      getSelectedVideo();
    };
    doStuff();
    // eslint-disable-next-line
  }, []);

  const getSelectedVideo = async () => {
    try {
      const token = await getTokenSilently();

      const response = await axios.get(`/api/videos/${videoID}/file`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // const video = new Blob([response.data], { type: 'video/mp4' });
      // const url = window.URL.createObjectURL(video);
      console.log(response.data);
      setVideoFilePath(response.data);
      //console.log('Video component sees video file path: ', videoFilePath);
      setIsLoaded(true);
    } catch (error) {
      console.error('getSelectedVideo caught error:' + error);
    }
  };

  return (
    <div className="video-player">
      {!isLoaded && <p>Loading...</p>}
      {isLoaded && (
        <video
          preload="metadata"
          controls="controls"
          className="video"
          controlsList="nodownload"
        >
          <source src={videoFilePath} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default Video;
